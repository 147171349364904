<template>
  <div>
    <div v-if="jurisdictionList.indexOf('admin-operation-cloud') !== -1">
      <div class="title-msg">云存储配置
        <i-switch size="large" v-model="total_config.show_status" style="margin-left: 20px" @on-change="changeSecond" >
          <span slot="open">开启</span>
          <span slot="close">关闭</span>
        </i-switch>
      </div>
      <div class="first" v-if="total_config.show_status == 1">
        <div class="all-list">
          <div class="label-new">云服务商:</div>
          <RadioGroup v-model="total_config.service_type" @on-change="changeConfigFirst">
            <Radio :label="1">腾讯云</Radio>
            <Radio :label="2">阿里云</Radio>
            <Radio :label="3">七牛云</Radio>
          </RadioGroup>
        </div>
        <div>
          <div class="all-list">
            <div class="label-new">空间域名Domain:</div>
            <Input v-model="total_config.domain" placeholder="请输入空间域名Domain" style="width: 350px" />
          </div>
          <div class="all-list">
            <div class="label-new">accessKey:</div>
            <Input v-model="total_config.access_key" placeholder="请输入accessKey" style="width: 350px" />
          </div>
          <div class="all-list">
            <div class="label-new">secretKey:</div>
            <Input v-model="total_config.secret_key" placeholder="请输入secretKey" style="width: 350px" />
          </div>
          <div class="all-list">
            <div class="label-new">存储空间名称:</div>
            <Input v-model="total_config.storage_title" placeholder="请输入存储空间名称" style="width: 350px" />
          </div>
          <div class="all-list">
            <div class="label-new">所属地域:</div>
            <Input v-model="total_config.area" placeholder="请输入所属地域" style="width: 350px" />
          </div>
          <div class="bottom-btn">
            <Button type="primary" style="margin-right: 30px" @click="configKeepTengxun">保存</Button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="jurisdictionList.indexOf('admin-operation-oem') !== -1">
      <div class="title-msg">OEM配置
        <i-switch size="large" v-model="oemList.show_status" style="margin-left: 20px" @on-change="changeThird" >
          <span slot="open">开启</span>
          <span slot="close">关闭</span>
        </i-switch>
      </div>
      <div class="first"  v-if="oemList.show_status">
        <div class="all-list">
          <div class="label-new">站点logo:</div>
         <div v-if="!oemList.site_logo">
           <Upload
               :format="['jpg','jpeg','png','gif']"
               :on-format-error="handleFormatError"
               :on-success="uploadImgSuccess"
               :on-progress="handleProgress"
               :show-upload-list="false"
               :headers="headers"
               :action="uploadImg">
             <div class="uplads_box">
               <div class="center">
                 <div style="margin-top:-10px"><Icon style="color:#9EA6B9" size="70" type="ios-add" /></div>
                 <div class="font14" style="margin-top:-10px;color:#9CA7B9">点击上传</div>
               </div>
             </div>
           </Upload>
         </div>
          <div class="logo-pic" v-else>
            <div class="clear-pic"  @click="clearLogo"><Icon type="ios-close-circle-outline" size="30" /></div>
            <img :src="oemList.site_logo">
          </div>
        </div>
        <div class="all-list">
          <div class="label-new">登录页背景图:</div>
         <div v-if="!oemList.login_bg">
           <Upload
               :format="['jpg','jpeg','png','gif']"
               :on-format-error="handleFormatError"
               :on-success="uploadImgSuccessBg"
               :on-progress="handleProgress"
               :show-upload-list="false"
               :headers="headers"
               :action="uploadImg">
             <div class="uplads_box">
               <div class="center">
                 <div style="margin-top:-10px"><Icon style="color:#9EA6B9" size="70" type="ios-add" /></div>
                 <div class="font14" style="margin-top:-10px;color:#9CA7B9">点击上传</div>
               </div>
             </div>
           </Upload>
         </div>
          <div class="logo-pic" v-else>
            <div class="clear-pic" @click="clearBg"><Icon type="ios-close-circle-outline" size="30" /></div>
            <img :src="oemList.login_bg">
          </div>
        </div>
        <div class="all-list">
          <div class="label-new">网站标题:</div>
          <Input v-model="oemList.site_title" placeholder="请输入网站标题" style="width: 350px" />
        </div>
        <div class="all-list">
          <div class="label-new">底部版权信息:</div>
          <Input v-model="oemList.floor_cr" placeholder="请输入底部版权信息" style="width: 350px" />
        </div>
        <div class="bottom-btn">
          <Button type="primary" style="margin-right: 30px" @click="submitOem">保存</Button>
        </div>
      </div>
    </div>
    <div style="width: 500px;">
      <div class="title-msg">短信配置
        <i-switch size="large" v-model="signArr.show_status"  style="margin-left: 20px" @on-change="change" >
          <span slot="open">开启</span>
          <span slot="close">关闭</span>
        </i-switch>
<!--        <div v-if="signArr.status == 1">-->
<!--          <Input style="margin-top: 20px" v-model="signArr.sign" type="textarea" :autosize="{minRows: 2,maxRows: 5}"-->
<!--                 placeholder="请输入短信签名"></Input>-->
<!--          <Button style="margin-top: 10px" type="primary" @click="autograph">提交签名</Button>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>
<script>
import {configAdd, configList} from '@/api/operation'
import config from '@/config/index'
import {getCookies} from '@/utils/helper'
import {configOpen,orm_handle,storage_add,storage_handle,sms_handle,getStorage} from "../../api/operation";
export default {
  data () {
    return {
      uploadImg:config.apiUrl+ '/adminapi/config/fileUpload',
      headers:{
        'hlzw-saas-authori-zation':getCookies('hlzw_saas_token')
      },
      showModel:false,
      synopsis:'',
      formItem:{
        input:''
      },
      showFirst:false,
      showSecond:false,
      showThird:false,
      animal:'4',
      value:'',
      typeRadio:'1',
      config:{
        status:0,
        show_status:false,
        aliyun_uploads:{
          domain_name:'',
          accesskey:'',
          secrekey:'',
          file_name:'',
          desc:'',
        },
        qiniu_uplaods:{
          domain_name:'',
          accesskey:'',
          secrekey:'',
          file_name:'',
          desc:'',
        },
        tengxun_uploads:{
          domain_name:'',
          accesskey:'',
          secrekey:'',
          file_name:'',
          desc:'',
        },
      },
      logoImage:'',
      bgImage:'',
      signArr:{
        status:0,
        show_status:false,
        sign:''
      },
      oemList:{
        site_title:'',
        show_status:false,
        floor_cr:'',
        site_logo:'',
        login_bg:''
      },
      jurisdictionList:[],
      total_config:{}
    }
  },
  created() {
    this.getConfig()
    this.jurisdictionList = this.$store.state.auth
  },
  mounted(){
  },
  methods: {
    autograph(){
      configOpen({
        type:'4',
        status:this.signArr.status
      }).then(res=>{
        this.$Message.success(res.msg)
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
    },
    topChange(){
      configOpen({
        type:'1',
        status:this.config.status
      }).then(res=>{
        this.$Message.success(res.msg)
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
    },
    changeConfigFirst(e) {
      getStorage({service_type:e}).then(res=>{
        if (res.data.length!=0) {
          this.total_config.domain = res.data.domain
          this.total_config.access_key = res.data.access_key
          this.total_config.secret_key = res.data.secret_key
          this.total_config.storage_title = res.data.storage_title
          this.total_config.area = res.data.area 
        }else{
          let data = {
          show_status:this.total_config.show_status,
          service_type:this.total_config.service_type,
          domain:'',
          access_key:'',
          secret_key:'',
          storage_title:'',
          area:''
        }
        this.total_config = data
        }
      })
      // this.typeRadio = e;
    },
    getConfig(){
      configList().then(res=>{
        this.oemList = res.data.oem
        this.oemList.show_status = res.data.oem.status == 1 ? true : false
        this.signArr = res.data.sms
        this.signArr.show_status = res.data.sms.status == 1 ? true : false
        this.total_config = res.data.storage
        this.total_config.show_status = res.data.storage.status == 1 ? true : false
        // if(this.config.show_status){
        //   this.config.status = 1
        // }
        // this.config.aliyun_uploads = this.total_config.aliyun_upload
        // this.config.qiniu_uplaods = this.total_config.qiniu_upload
        // this.config.tengxun_uploads = this.total_config.tengxun_upload
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
    },
    configKeep(){
      configAdd({
        type:this.typeRadio,
        status:this.config.status,
        domain:this.config.domain,
        access_key:this.config.access_key,
        secret_key:this.config.secret_key,
        storage_title:this.config.storage_title,
        area:this.config.area,}).then(res=>{
        this.$Message.success(res.msg)
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
    },
    configKeepQiniu(){
      configAdd({
        type:this.typeRadio,
        status:this.config.status,
        domain:this.config.qiniu_uplaods.domain,
        access_key:this.config.qiniu_uplaods.access_key,
        secret_key:this.config.qiniu_uplaods.secret_key,
        storage_title:this.config.qiniu_uplaods.storage_title,
        area:this.config.qiniu_uplaods.area,}).then(res=>{
        this.$Message.success(res.msg)
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
    },
    configKeepTengxun(){
      storage_add(this.total_config).then(res=>{
        this.$Message.success(res.msg)
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
    },
    configKeepAliyun(){
      configAdd({
        type:this.typeRadio,
        status:this.config.status,
        domain:this.config.aliyun_uploads.domain,
        access_key:this.config.aliyun_uploads.access_key,
        secret_key:this.config.aliyun_uploads.secret_key,
        storage_title:this.config.aliyun_uploads.storage_title,
        area:this.config.aliyun_uploads.area,}).then(res=>{
        this.$Message.success(res.msg)
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
    },
    change(){
      sms_handle().then(res=>{
        this.$Message.success(res.msg)
      })
    },
    changeSecond(){
      storage_handle().then(res=>{
        this.$Message.success(res.msg)
        this.getConfig()
      }).catch(()=>{
        let data = {
          show_status:this.total_config.show_status,
          domain:'',
          access_key:'',
          secret_key:'',
          storage_title:'',
          area:''
        }
        this.total_config = data
      })
    },
    changeThird(){
      orm_handle().then(res=>{
        this.$Message.success(res.msg)
        this.getConfig()
      }).catch(()=>{
        let data = {
          show_status:this.oemList.show_status,
          site_logo:'',
          login_bg:''
        }
        this.oemList = data
      })
    },
    handleSuccess(){

    },
    //文件上传类型错误
    handleFormatError () {
      this.$Message.warning('暂不支持上传此格式');
    },
    //添加图片上传图片成功
    uploadImgSuccess (e) {
      this.oemList.site_logo = e.data
      this.$Message.destroy()
      this.$Message.success(e.msg);
    },
    uploadImgSuccessBg(e){
      this.oemList.login_bg = e.data
      this.$Message.destroy()
    },
    // 文件上传时
    handleProgress(){
      const msg = this.$Message.loading({
        content: '上传中...',
        duration: 0
      });
      setTimeout(msg, 3000);
    },
    clearLogo(){
      this.oemList.site_logo = ''
    },
    clearBg(){
      this.oemList.login_bg = ''
    },
    submitOem(){
      console.log(this.oemList)
      configOpen(this.oemList).then(res=>{
        this.$Message.success(res.msg)
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
    },
  }
}
</script>
<style scoped>
.label-new {
  margin-right: 10px;
  width: 120px;
}
.logo-pic {
  width: 105px;
  height: 105px;
  position: relative;
}
.logo-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}
.clear-pic {
  position: absolute;
  top: -14px;
  right: -14px;
  z-index: 9999;
  color: #A0A4BD;
  cursor: pointer;
}
.uplads_box{
  width: 105px;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
  border:1px dashed  #A0A4BD;
  background: #F7F8FA;
  border-radius: 5px;
  cursor: pointer;
}
.title-msg {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
}
.all-list {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.bottom-btn {
  margin-left: 180px;
}
</style>
